<template>
  <section v-if="company">
    <CiHeading level="2" class="mb-3">
      {{ $t("settings.location-address") }}
    </CiHeading>
    <div class="settings-view__checkbox-container">
      <RdxCheckbox
        v-model="company.skip_company_name_in_location"
        class="settings-view__checkbox"
        :label="$t('settings.use-address-only')"
        data-cy="skip-company"
      />
      <RdxIcon
        v-tooltip="$t('settings.exclude-company-name')"
        class="ri-information-fill rdx-icon--16 rdx-icon--bluish"
      />
    </div>
    <span>{{ $t("settings.location-on-map") }}</span>
    <RdxInfo>
      {{ $t("settings.location-info-1") }}
    </RdxInfo>
    <CiGoogleMaps :address="fullAddress" z="12" />
    <RdxInfo class="mb-4" icon="ri-information-line">
      {{ $t("settings.location-info-2") }}
    </RdxInfo>
    <RdxInput
      v-model="company.address"
      :disabled="pending.load || pending.save"
      :label="$t('settings.street-address')"
    />

    <div class="row">
      <div class="col">
        <RdxInput
          v-model="company.city"
          :disabled="pending.load || pending.save"
          :label="$t('settings.city')"
        />
      </div>
      <div class="col">
        <RdxInput
          v-model="company.zipcode"
          :disabled="pending.load || pending.save"
          :label="$t('settings.postal-code')"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <RdxInput
          v-model="company.phone"
          :disabled="pending.load || pending.save"
          :label="$t('settings.primary-phone')"
        />
      </div>
      <div class="col">
        <RdxInput
          v-model="company.email"
          :disabled="pending.load || pending.save"
          :label="$t('settings.email')"
        />
      </div>
    </div>

    <RdxTextarea
      v-model="company.contact_details"
      :disabled="pending.load || pending.save"
      :label="$t('settings.contact-details')"
    />
    <RdxTextarea
      v-model="company.opening_hours"
      :disabled="pending.load || pending.save"
      :label="$t('settings.opening-hours')"
    />
    <RdxButton
      filled
      :disabled="pending.load || pending.save"
      class="mt-4"
      @click="saveSettings"
    >
      {{ $t("global.save") }}
    </RdxButton>
  </section>
</template>

<script>
import CiHeading from "@/components/atoms/CiHeading";
import {
  RdxInfo,
  RdxCheckbox,
  RdxIcon,
  RdxInput,
  RdxTextarea,
  RdxButton
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiGoogleMaps from "@/components/CiGoogleMaps/CiGoogleMaps.vue";

export default {
  name: "CarsInventorySettingsLocation",

  components: {
    CiHeading,
    RdxInfo,
    CiGoogleMaps,
    RdxCheckbox,
    RdxIcon,
    RdxInput,
    RdxTextarea,
    RdxButton
  },

  props: {
    selectedCompany: {
      type: Object,
      default: () => ({})
    },
    pending: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      company: { ...this.selectedCompany.attributes }
    };
  },

  computed: {
    fullAddress() {
      if (this.company) {
        const { name, city, address } = this.company;
        return `${
          this.company.skip_company_name_in_location ? "" : name || ""
        } ${city || ""} ${address || ""}`.trim();
      } else {
        return "";
      }
    }
  },

  watch: {
    selectedCompany() {
      this.company = { ...this.selectedCompany.attributes };
    }
  },

  methods: {
    saveSettings() {
      this.$emit("saveSettings", this.company);
    }
  }
};
</script>
